@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: var(--bg-1);
  font-weight: 300;;
  overflow: hidden;
}
.overflow {
  overflow: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px;

}

.mate {
  color: var(--text-0);
}

.error {
  color: rgb(247, 37, 37);
}

