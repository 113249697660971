.flex-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    min-width: 300px;
}


.file-p {
    margin-left: 20px;
    font-size: 15px;
    text-align: left;
    color: rgb(199, 199, 199);
}

.file-p span{
    display: inline-block;
    width: 100px;
    margin-right: 20px;
    font-size: 17px;
    color:var(--text-3)
}


.p {
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 10px;
    text-align: left;
    color: rgb(199, 199, 199);
}
.error{
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
    color: rgb(235, 43, 43)
}
.info{
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
    color: rgb(74, 229, 250)
}

.success{
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    color: rgb(10, 182, 82)

}


/* Create Project Classes for Button */

.btnDiv {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: right;
}
.btn {
    max-width: 120px;
}

.sendVerificaionBtn {
    display: inline;
    max-width: 220px;
;
}

.cancelBtn {
    background-color: rgba(231, 227, 227, 0.863);;
    box-shadow: -2px 2px white;
}

button:disabled, button:disabled:hover {
    background-color: #ccc;
    box-shadow: -2px 2px #ccc;
}


/* //delet project Css */

.delete {
    background-color: var(--delete);
    box-shadow: -2px 2px var(--delete-light);
}

.delete:hover {
    background-color: var(--delete);
    box-shadow: -2px 2px var(--delete-light);
}

/* Create Project Classes for Button */



/* Progress bar  ~ for Upload used in ProjectDetailModal */
.progress-bar {
    width: 0;
    height: 30px;
    line-height: 30px;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 20px 0 0 20px;
    background: linear-gradient(45deg, #4caf50, #1abc9c);
    transition: width 0.5s;
  }

  .progress-container {
    width: 90%;
    margin: 5px auto;
    
    background-color: #ddd;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }


  /* -------------------------------------------------------------------------- */
  /*                                   payment                                  */
  /* -------------------------------------------------------------------------- */

  .purchase {
    font-size: 20px;;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;

  }
  .detail { 
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
    color: cornsilk;
  }