.authContainer {
    width: 100vw;
    height: 100vh;
    background: 
    linear-gradient(to right, #2929281c, #29282848), /* Gradient Layer */
    url('../images/splashscreen.svg') center/cover;
    object-fit: unset;
    padding: 0px;
    margin: 0px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}
.welcomeMessage {
    width: 50%;
    height: 100%;
    display: flex;
    padding-top: 150px;
    padding-right: 50px;
    flex-direction: column;
}
.title {
    margin: 10px 30px 0 0;
    color: #f0f0f0;
    text-align: center;
    font-size: 40px;
}
.p {
    margin-top: 10px;
    color : #d8d8d8;
    font-size: 20px;
}
.mate {
    color : var(--text-2)
}
.loginContainer {
    position: absolute;
    width: 60%;
    height: 90%;
    border-radius: 30px;
    border: 0.5px solid var(--text-2);
    z-index: 1;


}

.signupContainer {
    position: absolute;
    width: 60%;
    height: 90%;
    z-index:0;
    border: 0.5px solid var(--text-2);
    border-radius: 30px;
    
}

.animationSlideDown {
    cursor: pointer;
    animation: go-to-back 1s ease-in-out; /* Use the animation name here */
   
}

@keyframes go-to-back {
    0% {
        transform: translateX(0px) scale(1.0);
    }
    50% {
        transform: translateX(100%) scale(0.9);
    }
    100% {
        transform: translateX(0px) scale(0.95);
        
    }
}


@media (max-width: 700px) {
  /* Your styles for heights less than 650 pixels go here */
  /* For example, you could change the background color */

  .welcomeMessage {
    width: 0%;
    overflow: hidden;
    visibility: hidden;
    padding: 0px;
  }

  .main {
    width: 100%;
  }
  .loginContainer {
    width: 80%;
    height: 80%;
  }  
  .signupContainer {
    width: 80%;
    height: 80%;
  }
    
}
