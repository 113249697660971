:root {
  /* --bg-1: #292f50;
  --bg-11: #252B48;
  --bg-2: #374055;
  --bg-3: #445069;
  --bg-4: #15aabf; */
  --bg-1: #151718;
  --bg-11: #191c1d;
  --bg-2: #1f2324;
  --bg-3: #1f2224;
  
  --bg-1-trans: #1517189f;
  --bg-11-trans: #191c1d9f;
  --bg-2-trans: #3437389f;
  --bg-3-trans: #1b1e1f9f;
  
  /* --text-0: #3a8572;
  --text-1: #499381;
  --text-2: #59bea6;
  --text-3: #7dc9b7; */
  
  --text-0: #02b4a5;
  --text-1: #02c5b5;
  --text-2: #04d3c2;
  --text-3: #04e9d6;

  --text-8: #0ad3c2;
  --text-9: #0df5e3;
  --text-3-trans: #7dc9b75e;

  --heading-0 : #b6a83f;
  --heading-1 : #d6c43a;
  --heading-2 : #cdbf55;
  --heading-3 : #e4d773;

  --border-white : #f0f0f0ea;
  --border-white-light : #b3b3b32e;

  --delete: rgb(235, 43, 43);
  --delete-light: rgb(240, 74, 74);

}


.display-content {
  display: contents !important;
}