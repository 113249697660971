.container {
  overflow: scroll;
  width: 30%;
  height: 100vh;
  position: absolute;
  z-index: 2;
  padding: 10px;
  right: 0;
  background-color: var(--bg-3);
  border-left: 1px solid rgba(115, 248, 151, 0.123);
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 700px) {
  .container {
    width: 75%;
  }
}
