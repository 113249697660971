
.project-list {
  overflow-y: scroll;
  padding: 5px;
  margin: 10px 0;
  border-radius: 20px;
  height: calc(100% - 100px);
  font-weight: 200;
}

p {
  text-align: center;
}

.no-project-found {
  margin-top: 10px;
  margin-right: 20px;
  color: #f8fef4a4;
}

.ul {
  list-style: none;
  padding: 0;

}

.ul li {
  margin: 12px 0;
  padding: 8px 20px;
  background-color: var(--bg-3-trans);
  color: white;
  border: 0.5px solid var(--text-3-trans);
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides any overflow beyond the defined width */
  text-overflow: ellipsis;
  height: 40px;
  align-items: center;
  transition: 0.1s ease;
}



.ul li:hover{
  background-color: var(--text-1);
  font-weight: 400;
  scale: 1.03;
}
.ul li.active {
  background: linear-gradient(90deg, var(--text-1), var(--text-3), var(--text-2));
  background-size: 200% 200%;
  animation: gradientAnimation 3s infinite;
  font-weight: 400;
  color: black;

}

/* -----------------------------------
---- Reusable code for ProjectfileSort and filter 
------------------------------------- */
.project-sort-list {
  padding: 5px;
  margin: 10px 0;
  border-radius: 10px;
  font-weight: 200;
  position: relative;
  z-index: 2;
  background-color: var(--bg-1);
  padding: 10px;
}

.project-sort-list .ul li{
  font-size: 14px ;
  margin-top: 5px ;
}



/* -----------------------------------
---- Reusable code for ProfileSectionList
------------------------------------- */
.profile-section-list {
  border-radius: 10px;
  margin-right: 20px;
  font-weight: 200;
  position: relative;
  z-index: 2;
  background-color: var(--bg-1);
  border: .5px solid rgba(243, 243, 243, 0.274);
  padding: 10px 20px;
}

.project-sort-list .ul li{
  font-size: 14px ;
  margin-top: 5px ;
}

.logoutList{
  position: absolute;
  right: 80%;
  top: 70%
}
.logoutList li:hover{
  background-color: var(--text-1);
  font-weight: 300;
  scale: 1.0;
}





@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
