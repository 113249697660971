.container {
    
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.button {
    width: 60%;
    margin-top: 30px;
    margin-bottom: 40px;
}

.fileIcon {
    width: 150px;
    height: 0px;
    opacity: 0;
    transition: 2s ease;
}

.fileIconLoading {
    width: 100px;
    height: auto;
}

.document {
    color: #b3a104
}

.pdf {
    color: rgb(228, 113, 132);
}

.jpg {
    color: rgb(207, 131, 16);

}

.png {
    color: rgb(16, 121, 182);
}
.rotate {
    animation: rotate 3s infinite;
    border: 0px;
}

.delete {
    bottom: 10px;
    padding: 10px 0px;
}
.deleteBtn {
    background-color: rgb(248, 43, 43);
    box-shadow: -1px 2px 2px rgb(199, 2, 2);
    color: whitesmoke;
}
.deleteBtn:hover {
    background-color: rgb(199, 2, 2);
    box-shadow: none;
}



@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}