
.button {
    width: fit-content;
    margin-right: 20px;
}

.project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 10px 15px 10px;
    height: fit-content;
}

.project-header-action {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.project-header-action-delete {
    background-color: rgb(199, 28, 28);
    box-shadow: -2px 2px 2px #807e7e;
    padding-right:  0px;
    border: none;
}

.project-header-action-delete:hover,
.project-header-action-delete:active {
    background-color: rgb(150, 21, 21);
}


.enable {
    display: block;
    width: fit-content;
    height: 0px ;
    transition: 0.3s ease;
    display: none;

}


.filter {
    display: block;
}


.hamburger {
  visibility: hidden;
  color: wheat;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-right: 20px;

}



@media (max-width: 700px) {

  .hamburger {
    visibility: visible;
    
  }
}
