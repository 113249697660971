.container {
  background: url("../../images/background-img-transparent.png");
  height: 40vh;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 20%;
  position: absolute;
  top: 30%;
  left: 0;
}

.profileCircle {
  padding: 0px;
  overflow: hidden;
  width: 250px;
  height: 250px;
  border-radius: 60px;
}
.img {
  width: 250px;
  height: 250px;
}
.editImage {
  width: 25px;
  height: 25px;
  padding: 0px;
}

.circle {
  background-color: rgba(255, 255, 255, 0.815);
  position: absolute;
  top: 90%;
  left: 190px;
  padding: 5px;
  border: 1px;
  height: 35px;
}

.main {
  background-color: var(--bg-2);
  width: 100%;
  margin: 10px;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid var(--text-1);
  border-radius: 20px;
  color: whitesmoke;
  letter-spacing: 1.1px;
  padding-left: 20px;
}

.name {
  font-size: 22px;
  color: azure;
}

@media (max-width: 700px) {
  .container {
    height: 20vh;
  }
  .content {
    top: 15%;
  }
  .profileCircle {
    padding: 0px;
    overflow: hidden;
    width: 150px;
    height: 150px;
    border-radius: 60px;
  }
  .img {
    width: 150px;
    height: 150px;
  }
}
