/* html {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #19161b;
  }
   */
  .inputBox {
    position : relative;
    max-width: 600px;
    width: 100%;
    min-width: 260px;
    margin: 25px 0px;
  }
  
  .inputBox input {
    width: 100%;  
    border-radius: 5px;
    background: #19161b;
    color: aliceblue;
    font-size: 18px;
    border : 1px solid #888888;
    outline: none;
    padding: 15px;
  }
  
  .inputBox span {
    position: absolute;
    color: #888888;
    left: 0px;
    top: 0px;
    padding: 15px;
    font-size: 18px;
    pointer-events: none;
    transition: 0.5s ease;
  }
  .inputBox input:not(:placeholder-shown) ~ span,
  .inputBox input:focus ~ span
   {
    /* Your styles when input is focused or has a valid email address */
    color: #00e1c6;
    transform: translate(12px, -10px);
    font-size: 15px;
    padding: 0 10px;
    background: #19161b;
    letter-spacing: 2.5px;
  }