.container {
  padding-top: 240px;
  padding-left: 50px;
}

.login {
  font-size: 30px;
  font-weight: bold;
  color: whitesmoke;
  border-left: 4px solid var(--text-1);
  padding-left: 20px;
}

.loginDevice {
  text-align: left;
  margin-top: 20px;
  font-size: 25px;
  margin-left: 10px;
}

.devices {
  margin-left: 10px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 5px;
  margin-bottom: 20px;
}

.oneDevice {
  border: 1px solid var(--text-1);
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: whitesmoke;
  font-size: 18px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px 40px;
}
.oneDevice div,
.oneDevice button {
  width: 18%;
  text-align: center;
}
.bar {
  background-color: var(--text-0);
  padding: 10px 50px;
}

.logout {
  color: white;
  background-color: rgb(240, 57, 57);
  display: block;
  width: 120px;
  box-shadow: 1px 3px 1px red;
}
.logout:hover {
  background-color: rgb(192, 22, 0);
}

.currentUser {
  background-color: #2e2d2d71;
  border-color: var(--heading-1);
  pointer-events: none;
}

@media (max-width: 700px) {
  .container {
    padding-top: 200px;
    padding-left: 10px;
  }

  .devices {
    padding: 0px;
    margin: 4px;
  }

  .oneDevice {
    gap: 5px;
    font-size: 17px;
    justify-content: space-around;
    padding: 10px 5px;
  }
  .oneDevice div,.oneDevice button {
    width: 22%; /* Replace with your desired width */
  }  
  .oneDevice div:nth-child(4) {
    width: 0px; /* Replace with your desired width */
    height: 0px;
    overflow: hidden;
    padding: 0px;
    margin : 0px
  }
}
