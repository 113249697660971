.button {
  width: 100%;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 30px;
  background: var(--text-3);
  outline: none;
  border: 0.5px solid wheat;
  scale: 1;
  transition: all 0.3s ease;
  cursor: pointer;
  /* box-shadow: -1px 3px  #3b9238e7; */
  box-shadow: -1px 3px var(--text-1);
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.button:hover,
.button:active {
  background-color: var(--text-1);
  box-shadow: none;
  transform: translateY(4px) translateX(-1px);
}
.button:disabled {
  background-color: gray;
  box-shadow: -1px 3px gray;
  color: black;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 10px;
  align-items: center;
  padding-right: 30px;
  padding-left: 10px;
  padding-top: 5px;
}
.button-span {
  width: fit-content;
  height: fit-content;
  padding-right: 10px;
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides any overflow beyond the defined width */
  text-overflow: ellipsis;
}

.button-image {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-top: 3px;
}

/* -------------------------------------------- */
/* Combo Button classes  */
/* -------------------------------------------- */

.btnDiv {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: right;
}
.btn {
  max-width: 120px;
}

.cancelBtn {
  background-color: rgba(231, 227, 227, 0.863);
  box-shadow: -2px 2px white;
}

button:disabled,
button:disabled:hover {
  background-color: #ccc;
  box-shadow: -2px 2px #ccc;
}

/* //delet project Css */

.delete {
  background-color: var(--delete);
  box-shadow: -2px 2px var(--delete-light);
}

.delete:hover {
  background-color: var(--delete);
  box-shadow: -2px 2px var(--delete-light);
}

@media (max-width: 700px) {
  .button-name {
    width: 0px;
    visibility: hidden;
    padding: 0px;
  } 
   .button-container {
    padding-right: 10px;
  }
}
