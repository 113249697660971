.container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

}
.containerIn {
    animation: slide-in 2s ease;

}
.containerOut {
    animation: slide-out 2s ease;
}

.loadingContent {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-color: var(--bg-3); */
    background-image: linear-gradient(90deg,rgba(13, 14, 13, 0.842), rgba(14, 14, 14, 0.822)), url('../images/moving-bg.svg');
        border-bottom: 1px solid rgba(245, 245, 245, 0.37);

        animation: slide-in 0.1s ease;


}

.loadingImg {
    width: 100px;
    height: inherit;
    padding: 0px;
    animation: rotate 2s linear infinite;
    transform-origin: center;

}
.loadingDataMsg {
    color: whitesmoke;
    font-size: 20px;
}
.retryImage {
    width: 100px;
    height: 100px;
    margin-right: 50px;
}
.retryContent {
    background-color: var(--bg-1);
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.retryContainer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--bg-3);
}




@keyframes rotate {

    0% {
        transform: rotate(0deg);
        scale: 0.75;
    }

    50% {
        transform: rotate(180deg);
        scale: 1;
    }

    100% {
        transform: rotate(360deg);
        scale: 0.75;
    }

}

@keyframes slide-in {

    0% {
        transform: translate(0, -100%);
    }

    100% {
        transform: translate(0, 0);
    }

}

@keyframes slide-out {

    0% {
        transform: translate(0, -100%);
    }

    100% {
        transform: translate(0, 0);
    }

}