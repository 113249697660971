.splashscreen {
  width: 100%;
  height: 100vh;
  background: url("../images/splashscreen.svg");
  object-fit: unset;
  padding: 0px;
  margin: 0px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.container {
  position: absolute;
  width: 70%;
  height: 500px;
  margin-top: 50px;

  margin-left: 50px;
}

.img {
  width: 150px;
  height: 150px;
  animation: zoom-in-zoom-out 15s ease infinite;
}

.mycloud {
  color: rgb(223, 223, 223);
  font-size: 35px;
}

.mate {
  color: var(--text-3);
}

.p {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 10px;
  color: white;
  font-size: 20px;
}

.splashscreenBackground {
  position: absolute;
  width: 50%;
  height: 50%;
  /* background: url('../images/background-img-file.jpg'); */
  opacity: 0.2;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 200px;
  margin-left: 200px;
}
.startBtn {
  width: 30%;
  min-width: 150px;
  max-width: 200px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0, 0);
  }
}
