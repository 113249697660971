.blur {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #272727b7;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}