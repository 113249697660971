
.loginContainer {
    width: 100%;
    height: 100%;
    background-color: var(--bg-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.form {
    height: 100%;
    width: 80%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img{
    width: 200px;
    height: 200px;
    text-align: center;
    margin-top: 20px;
    mix-blend-mode: var(--bg-1);
}
.p {
    color: var(--text-2);
    margin-bottom: 10px;
}
.createAccount{
    color: var(--text-2);
    font-size: 18px;
}

.createP {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 80%;
    

} 
.error{
    color: red;
    font-size: 14px;
    margin-top: -10px;
    text-align: left;
    width: 100%;
}
.invalidPassword{
    color: rgb(237, 45, 45);
    font-size: 17px;
    text-align: center;
}
.span {
     margin-left: 10px;
     color: var(--text-2);;
     cursor: pointer;
}



@media (max-height: 900px) {
  /* Your styles for heights less than 650 pixels go here */
  /* For example, you could change the background color */
  .img {
    width: 120px;
    height: 120px;
    margin-top: 30px;
  }
  .p {
    color: var(--text-2);
    margin-bottom: 0px;
  }

  .error {
    margin-top: -15px;
    text-align: left;
    width: 100%;
  }
}
